import { ROUTES } from "./routes";

export const ROUTES_NO_HELP_TIPS = [
  ROUTES.MYDATA,
  ROUTES.BDP_CONSIGNMENT,
  ROUTES.ANALYTICS_CONSIGNMENT,
  ROUTES.DIAGMON_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT_WITH_KR,
];

// NOTE: separate this variable into two if there are
//       routes that has no header but has a footer
//       or vice versa
//       e.g.:
//         - export const ROUTES_NO_HEADER
//         - export const ROUTES_NO_FOOTER
export const ROUTES_NO_HEADER_AND_FOOTER = [
  ROUTES.BDP_CONSIGNMENT,
  ROUTES.ANALYTICS_CONSIGNMENT,
  ROUTES.DIAGMON_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT_WITH_KR
];

export const ROUTES_NO_COOKIE_NOTICE = [
  ROUTES.BDP_CONSIGNMENT,
  ROUTES.ANALYTICS_CONSIGNMENT,
  ROUTES.DIAGMON_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT_WITH_KR
];

export const ROUTES_CONSIGNMENT_PAGE = [
  ROUTES.BDP_CONSIGNMENT,
  ROUTES.ANALYTICS_CONSIGNMENT,
  ROUTES.DIAGMON_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT_WITH_KR
]
