import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import "./Layout.scss";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
// import { useUserHooks } from "../../hooks/user-hooks";
import { useI18Next } from "../../i18next";
import { ScrollToTop } from "../__common/ScrollToTop/ScrollToTop";
import { useEffect } from "react";
import IdleTimer from "../../utils/IdleTimer";
import { useAuth } from "../../hooks/auth-hooks";
import { UtilsUser } from "../../utils/UtilsUser";
import { useUserHooks } from "../../hooks/user-hooks";
import { useLocale } from "../../hooks/locale-hooks";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { EuCookieAlert, NonEuCookieAlert } from "../CookieModal/CookieModal";
import { UserState } from "../../store/reducers/user-reducer";
import { Cookie } from "../../services/storage";
import { REDIRECT_URI, USER_SESSION_ID } from "../../constants/storage";
import { useQuery } from "../../hooks/query-hooks";
import { HelpTipsPoupup } from "../HelpTipsPopup/HelpTipsPopup";
import { ROUTES } from "../../configs/routes";
import { useLayout } from "../../hooks/layout-hooks";
import { UtilsBrowser } from "../../utils/UtilsBrowser";
import classNames from "classnames";
import { URLS } from "../../constants/urls";
import { routeShouldRenderComponent } from "../../utils/UtilsLayout";
import {
  ROUTES_NO_COOKIE_NOTICE,
  ROUTES_NO_HEADER_AND_FOOTER,
  ROUTES_NO_HELP_TIPS,
} from "../../configs/layout";

interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = (props) => {
  const history = useHistory();
  const query: any = useQuery();
  const { logout } = useAuth();
  const { getUserData, getUserSurveyResponse, loadUserCountryCode } =
    useUserHooks();
  const { pathname } = useLocation();
  const redirectUri = Cookie.get(REDIRECT_URI);

  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  useLocale();
  useLayout();

  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const { getUserCountry, setSelectedCountry } = useLayout();
  const { i18next } = useI18Next();
  // if (i18next.language !== layoutState.languageTrans) {
  //   i18next.changeLanguage(layoutState.languageTrans);
  // }

  const redirectToChinaLocalSite = () => {
    window.location.replace(URLS.CHINA_LOCALSITE);
  };
  useEffect(() => {
    if (userCountryCode) {
      if (userCountryCode === "CN") {
        redirectToChinaLocalSite();
      }
      const country = getUserCountry();
      setSelectedCountry(country);
    }
  }, [userCountryCode]);

  useEffect(
    () => {
      if (redirectUri && query.get("success")) {
        Cookie.remove(REDIRECT_URI);
        history.replace(redirectUri);
      }
      if (pathname === "/about/grps") {
        history.replace(ROUTES.ABOUT_GRPS);
      }
    },

    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      getUserData();
      loadUserCountryCode();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (userCountryCode === "CN") {
        if (userCountryCode) {
          redirectToChinaLocalSite();
        }
      }
      const storageDiffChecker1 = (e: any) => {
        if (e.key === USER_SESSION_ID && !e.oldValue && e.newValue) {
          getUserData();
        }
      };

      window.addEventListener("storage", storageDiffChecker1);

      return () => {
        window.removeEventListener("storage", storageDiffChecker1);
      };
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (UtilsUser.isUserLoggedIn()) {
        getUserSurveyResponse();
        const timer = new IdleTimer({
          timeout: 600, //10 minutes on idle
          onTimeOut: logout,
          onExpired: logout,
        });

        return () => {
          timer.cleanup();
        };
      }
    },
    // eslint-disable-next-line
    [userState.userData]
  );

  return i18next.isInitialized && userCountryCode !== "CN" ? (
    <div className="body">
      {!UtilsBrowser.isInternetExplorer() &&
        !routeShouldRenderComponent(pathname, ROUTES_NO_HEADER_AND_FOOTER) && (
          <Header />
        )}
      {!UtilsBrowser.isInternetExplorer() &&
        !userState.isGeoIPLoading &&
        !routeShouldRenderComponent(pathname, ROUTES_NO_COOKIE_NOTICE) && (
          <>
            <NonEuCookieAlert />
            <EuCookieAlert />
          </>
        )}
      <main
        id="main-body"
        className={classNames({
          "snap-scroll": pathname === ROUTES.ROOT,
        })}
      >
        {!UtilsBrowser.isInternetExplorer() &&
          !routeShouldRenderComponent(pathname, ROUTES_NO_HELP_TIPS) && (
            <HelpTipsPoupup />
          )}

        <div className="layout">{props.children}</div>
        <ScrollToTop />
        {!UtilsBrowser.isInternetExplorer() &&
          <Footer />
        }
      </main>
    </div>
  ) : (
    <></>
  );
};
