import React, { useEffect } from "react";
import "./ServiceRequestToastError.scss";
import { SVG_ICONS, Svg } from "../../../../components/__common/Svg/Svg";
import { Container } from "../../../../components/Grid/Grid";
import { Typography } from "../../../../components/__common/Typography/Typography";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { actionSetShowServiceToastError } from "../../../../store/actions/mydata-actions";
import { SAMSUNG_ACCOUNT_PORTAL } from "../../../../constants/appConstants";

interface Props {
  isSAUnverified: boolean;
  show: boolean;
  onClose: () => void;
}

export const ServiceRequestToastError: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isSAUnverified = false, show = false, onClose } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        actionSetShowServiceToastError({
          isError: false,
          hasRendered: true,
          isSAUnverifiedError: isSAUnverified,
        }),
      );
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      <div className={`toast ${show ? "show" : "hidden"}`}>
        <Typography
          variant="body1"
          component="span"
          className="toast__info"
          weight="w700"
        >
          {isSAUnverified
            ? t("PRIVACY_MYDATA_REQ_FAILED_UNVERIFIED_SA_TOAST")
            : `${t("PRIVACY_MYDATA_REQ_ERROR_TITLE")}  ${" "} ${t(
                "PRIVACY_MYDATA_REQ_ERROR_BODY",
              )}`}
        </Typography>
        <Typography
          variant="body2"
          component="span"
          className="toast__ok"
          weight="w700"
        >
          {isSAUnverified && (
            <a href={SAMSUNG_ACCOUNT_PORTAL} target="_blank">
              {t("PRIVACY_MYDATA_REQ_FAILED_UNVERIFIED_SA_TOAST_LINK")}
            </a>
          )}
        </Typography>

        <button className="toast__close" onClick={onClose}>
          <Svg
            icon={SVG_ICONS.DRAWER_CLOSE}
            width={16}
            height={16}
            onClick={onClose}
          />
        </button>
      </div>
    </Container>
  );
};
