import React, { useEffect } from "react";
// import { useLocation } from "react-router";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
}
const ScrollToTop: React.FC<Props> = (props) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (history.action !== "REPLACE") {
      document.querySelector("main")!.scrollTo(0, 0);
      window.scrollTo(0, 0);
    }
  }, [location, history]);

  return <>{props.children}</>;
};

export default ScrollToTop;
