import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ROUTES } from "../../configs/routes";
import { Svg, SVG_ICONS } from "../../components/__common/Svg/Svg";

import "./FooterMobile.scss";
import { ApplicationState } from "../../store";
import { UserState } from "../../store/reducers/user-reducer";
import triggerGAEvent from "../../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_FOOTER,
  EVENT_FOOTER_ACTION_COOKIE_POLICY,
  EVENT_FOOTER_ACTION_PRIVACY_POLICY,
  EVENT_ACTION_MANAGE_COOKIES,
} from "../../constants/analytics";
import { LayoutState } from "../../store/reducers/layout-reducer";
import { useLayout } from "../../hooks/layout-hooks";
import { routeShouldRenderComponent } from "../../utils/UtilsLayout";
import { ROUTES_CONSIGNMENT_PAGE } from "../../configs/layout";
interface Props {
  onClickLanguage?: () => void;
  onClickManageCookie?: () => void;
  country?: string;
  language?: string;
}

export const FooterMobile: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { setTheme } = useLayout();
  const wrapClass = classNames("responsive--layout");
  const { pathname } = useLocation();

  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { userRegion } = userState;

  const handleLanguageOnClick = () => {
    if (props.onClickLanguage) {
      props.onClickLanguage();
    }
  };

  const handleManageCookieClick = (e: any) => {
    e.preventDefault();
    if (props.onClickManageCookie) {
      triggerGAEvent(EVENT_CATEGORY_FOOTER, EVENT_ACTION_MANAGE_COOKIES);
      props.onClickManageCookie();
    }
  };

  return (
    <div
      className={classNames("footer-mobile", {
        "snap-scroll": pathname === ROUTES.ROOT,
        "consignment-footer": ROUTES_CONSIGNMENT_PAGE.includes(pathname),
      })}
    >
      <div className={wrapClass}>
        {/* <div className="footer-mobile__logo">
          <Svg
            className="arrow-right"
            width={212}
            height={27}
            icon={SVG_ICONS.FOOTER_LOGO}
          />
        </div> */}
        {!routeShouldRenderComponent(pathname, ROUTES_CONSIGNMENT_PAGE) &&
        <div className="footer-mobile__content">
          
          <div className="footer-mobile__content__link sharp-sans">
            <Link
              to={ROUTES.PRIVACY_POLICY}
              onClick={() =>
                triggerGAEvent(
                  EVENT_CATEGORY_FOOTER,
                  EVENT_FOOTER_ACTION_PRIVACY_POLICY
                )
              }
            >
              {t("PRIVACY_HEADER_MENU_PRIVACY_POLICY")}
            </Link>
          </div>
          {userRegion.isFromEurope && (
            <>
              <div className="footer-mobile__content__link sharp-sans">
                <Link
                  to={ROUTES.COOKIE_POLICY}
                  onClick={() =>
                    triggerGAEvent(
                      EVENT_CATEGORY_FOOTER,
                      EVENT_FOOTER_ACTION_COOKIE_POLICY
                    )
                  }
                >
                  {t("PRIVACY_FOOTER_MENU_COOKIES")}
                </Link>
              </div>
              <div
                className={classNames(
                  "footer-mobile__content__link",
                  "footer-mobile__content__language"
                )}
              >
                <Link
                  className="footer-mobile__content__language__link sharp-sans"
                  to="#"
                  onClick={(e) => handleManageCookieClick(e)}
                >
                  {t("PRIVACY_FOOTER_MENU_MANAGE_COOKIES")}
                </Link>
              </div>
            </>
          )}
          <div
            className={classNames(
              "footer-mobile__content__link",
              "footer-mobile__content__language"
            )}
          >
            <Link
              className={classNames(
                "footer-mobile__content__language__link sharp-sans",
                { "max-width": layoutState.countryCode === "VC" }
              )}
              to="#"
              onClick={handleLanguageOnClick}
            >
              <span className="country">
                {props.country}&nbsp;/&nbsp;
                {props.language}
              </span>
              <Svg
                className="arrow-right"
                icon={SVG_ICONS.ARROW_RIGHT_MOBILE}
                width={16}
                height={16}
              />
            </Link>
          </div>
        </div>
        }
        <div className="footer-mobile__content__copyright sharp-sans">
          Copyright © Samsung Electronics Co, Ltd. All Rights Reserved
        </div>
        <div className="footer-mobile__content__themes">
          <span className="icon-link" onClick={() => setTheme(true)}>
            <Svg
              width={16}
              height={16}
              icon={SVG_ICONS.FOOTER_THEME_HIGH_CONTRAST}
            />
          </span>
          <span
            className={classNames("icon-link", "icon-link-last")}
            onClick={() => setTheme(false)}
          >
            <Svg width={16} height={16} icon={SVG_ICONS.FOOTER_THEME_DEFAULT} />
          </span>
        </div>
      </div>
    </div>
  );
};
